<template>
  <div class="container" style="padding-top: 15vh">
    <div class="font-text2">임시저장하신 서비스가 있습니다</div>
    <div class="size-36"><b>임시저장</b>된 서비스로 진행하시겠어요?</div>
    <div class="flex-center" style="margin-top:140px">
      <button class="button" @click="$emit('new')">새 서비스로 개설</button>
      <button class="button is-primary-2" style="margin-left:12px" @click="$emit('temp')">임시저장한 서비스로 진행</button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SuperBuilderTemp",
    created() {

    },
    data() {
      return {}
    },
    methods: {}
  }
</script>

<style lang="stylus" scoped>
  @import "~assets/css/lp_main"

</style>